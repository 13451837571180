import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { isLargeFormatDevice } from "../../utils/screenSizeUtils";
import { CabButton } from "@CabComponents/CabButton";
import CabinetPage from "../../components/Common/CabinetPage";
import { useState } from "react";
import { Leader } from "../../store";
import { 
  Contact, ContactCategory, ContactType 
} from "../../store/cabinetApi/generated/crm";
import NewContactModal from "./NewContactModal";
import colors from "../../colors";
import CRMContactsTable from "../../components/CRM/CRMContacts/CRMContacts";
import SearchInputForm, { SearchForm } from "../../components/CRM/SearchInputForm";
import useContactsList from "../../components/CRM/CRMContacts/contactsListHook";


const CRMContacts = ({
  contactsListData, contactPageSize, contactCategories, contactTypes, onCreateContact, isCreatingContact,
  leaderOptions, onSearch
}: {
  contactsListData: ReturnType<typeof useContactsList>;
  contactCategories: ContactCategory[];
  contactTypes: ContactType[];
  contactPageSize: number;
  onCreateContact: (contact: Omit<Contact, 'id'>, leaderIds?: Leader['id'][]) => Promise<void>;
  isCreatingContact: boolean;
  leaderOptions: Leader[];
  onSearch: (search: string) => void;
}) => {
  const [openNewContactModal, setOpenNewContactModal] = useState(false);
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleSearch = ({ search }: SearchForm) => {
    onSearch(search);
  };

  return (
    <CabinetPage
      pageName="People"
      headerContent={
        <Box width='100%' display='flex' justifyContent='space-between'>
          <Typography variant="h1">
            People
          </Typography>
          {!isSmDown && (
            <SearchInputForm
              onSearch={handleSearch}
              sx={{ flex: 1, maxWidth: 500, paddingLeft: 4, paddingRight: 4 }}
            />
          )}
          {isLargeFormatDevice() && (
            <CabButton
              onClick={() => setOpenNewContactModal(true)}
            >
              New Contact
            </CabButton>
          )}
        </Box>
      }
    >
      <>
        <Box padding={2} display="flex" flexDirection="column" flex={1} gap={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1" color={colors.black700}>
              Your team's contact list
            </Typography>
            {isSmDown && (
              <SearchInputForm onSearch={handleSearch} />
            )}
          </Box>

          <Box position="relative" height="100%">
            <CRMContactsTable
              {...contactsListData}
              interactive
              paginate
              defaultHiddenFields={['title']}
              sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
            />
          </Box>
        </Box>

        <NewContactModal
          open={openNewContactModal}
          onClose={() => setOpenNewContactModal(false)}
          contactCategories={contactCategories}
          contactTypes={contactTypes}
          onCreateContact={onCreateContact}
          isCreatingContact={isCreatingContact}
          leaderOptions={leaderOptions}
        />
      </>
    </CabinetPage>
  );
};


export default CRMContacts;
