import { useNavigate } from "react-router";
import { PAGE_URL } from "../../constants";
import { crm } from "../../store/cabinetApi/crm";
import { Contact } from "../../store/cabinetApi/generated/crm";
import { Leader, RootState, ThunkDispatchType } from "../../store";
import CRMContacts from "./CRMContacts";
import { useDispatch, useSelector } from "react-redux";
import { useMountEffect } from "../../utils/hooks";
import { fetchLeaders } from "../../store/leaders/actions";
import { createSearchParams } from "react-router-dom";
import useContactsList from "../../components/CRM/CRMContacts/contactsListHook";


const pageSize = 20;

const CRMContactsContainer = () => {
  const contactsListData = useContactsList({ pageSize });

  // TODO: remove limit from this request. Only adding it to get the right response shape for now
  const { data: contactCategories } = crm.useAppCrmContactCategoriesListQuery({ limit: 1000 });
  const { data: contactTypes } = crm.useAppCrmContactTypesListQuery({ limit: 1000 });
  const [createContact, { isLoading: isCreatingContact }] = crm.useAppCrmContactsCreateMutation();
  const [createRelationship, { isLoading: relationshipIsCreating }] = crm.useAppCrmLeaderRelationshipsCreateMutation();

  const leaders = useSelector((root: RootState) => root.leaders.leaders);

  const dispatch = useDispatch<ThunkDispatchType>();

  const navigate = useNavigate();

  useMountEffect(() => {
    dispatch(fetchLeaders());
  });

  const handleCreateContact = async (contact: Omit<Contact, 'id'>, relationshipLeaders?: Leader['id'][]) => {
    const c = await createContact({
      id: -1,
      ...contact,
    });
    if (relationshipLeaders && c.data && "id" in c.data) {
      const relationshipPromises = relationshipLeaders.map(rl => {
        return createRelationship({
          id: -1, relationship_strength: '', relationship_strength_auto: '',
          contact: c.data.id, leader: rl
        });
      });
      await Promise.all(relationshipPromises);
    }
  };

  const handleSearch = (search: string) => {
    navigate({
      pathname: PAGE_URL.CRM_SEARCH,
      search: createSearchParams({ search }).toString(),
    });
  };

  return <CRMContacts
    contactsListData={contactsListData}
    contactPageSize={pageSize}
    contactCategories={contactCategories?.data || []}
    contactTypes={contactTypes?.data || []}
    onCreateContact={handleCreateContact}
    isCreatingContact={isCreatingContact || relationshipIsCreating}
    leaderOptions={leaders}
    onSearch={handleSearch}
  />;
};

export default CRMContactsContainer;
